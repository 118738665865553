import React from "react";
import "./style.scss";
import BeforeAfterSlider from "../../../common/BeforeAfter";
import shadowCreation1 from "../../../assets/FlowEdit/FifthFoldInner/shadow_creation1.webp";
import shadowCreation2 from "../../../assets/FlowEdit/FifthFoldInner/shadow_creation2.webp";
import shadowRetain1 from "../../../assets/FlowEdit/FifthFoldInner/shadow_retain1.webp";
import shadowRetain2 from "../../../assets/FlowEdit/FifthFoldInner/shadow_retain2.webp";
import shadowRemove1 from "../../../assets/FlowEdit/FifthFoldInner/shadow_remove1.webp";
import shadowRemove2 from "../../../assets/FlowEdit/FifthFoldInner/shadow_remove2.webp";

const FifthFoldInner = () => {
  const fifthInnerData = [
    {
      id: 1,
      beforeImage: shadowCreation1,
      afterImage: shadowCreation2,
      title: "Shadow Creation",
      description:
        "Add depth to your images by creating realistic shadows that give products a three-dimensional look, ensuring they appear more lifelike and professional.",
    },

    {
      id: 2,
      beforeImage: shadowRetain1,
      afterImage: shadowRetain2,
      title: "Shadow Retain",
      description:
        "Maintain natural shadows in your images while enhancing their appearance for a polished, professional final product.",
    },

    {
      id: 3,
      beforeImage: shadowRemove1,
      afterImage: shadowRemove2,
      title: "Shadow Remove",
      description:
        "Efficiently remove unwanted or distracting shadows to ensure a clean, sleek look for your product images, ideal for uniformity across catalogs.",
    },
  ];

  return (
    <div className="fifth_fold_inner">
      <div className="fold_content">
        <h2 className="heading">Shadow</h2>
        <div className="services">
          {fifthInnerData?.map((inner) => {
            return (
              <div className="service_box">
                <div className="service_image">
                  <BeforeAfterSlider
                    beforeImage={inner.beforeImage}
                    afterImage={inner.afterImage}
                  />
                </div>
                <div className="service_content">
                  <h2 className="service_heading">{inner.title}</h2>
                  <p className="service_description">{inner.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FifthFoldInner;
