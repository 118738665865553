import React from "react";
import "./style.scss";
import BeforeAfterSlider from "../../../common/BeforeAfter";
import colorMatch1 from "../../../assets/FlowEdit/FourthFoldInner/color_match1.webp";
import colorMatch2 from "../../../assets/FlowEdit/FourthFoldInner/color_match2.webp";
import colorWays1 from "../../../assets/FlowEdit/FourthFoldInner/color_ways1.webp";
import colorWays2 from "../../../assets/FlowEdit/FourthFoldInner/color_ways2.webp";
import colorEnhancement1 from "../../../assets/FlowEdit/FourthFoldInner/color_enhancement1.webp";
import colorEnhancement2 from "../../../assets/FlowEdit/FourthFoldInner/color_enhancement2.webp";

const FourthFoldInner = () => {
  const fourthInnerData = [
    {
      id: 1,
      beforeImage: colorMatch1,
      afterImage: colorMatch2,
      title: "Color Matching",
      description:
        "Ensure uniformity across product images by matching colors accurately, perfect for e-commerce platforms where consistency is key.",
    },

    {
      id: 2,
      beforeImage: colorWays1,
      afterImage: colorWays2,
      title: "Colorways",
      description:
        "Create multiple color variations of your product images without the need for new photoshoots, allowing for faster updates to your online catalog.",
    },

    {
      id: 3,
      beforeImage: colorEnhancement1,
      afterImage: colorEnhancement2,
      title: "Color Enhance",
      description:
        "Enhance the vibrancy and richness of colors in your images, making products appear more appealing and true-to-life while maintaining color accuracy.",
    },
  ];

  return (
    <div className="fourth_fold_inner">
      <div className="fold_content">
        <h2 className="heading">Color Solutions</h2>
        <div className="services">
          {fourthInnerData?.map((inner) => {
            return (
              <div className="service_box">
                <div className="service_image">
                  <BeforeAfterSlider
                    beforeImage={inner.beforeImage}
                    afterImage={inner.afterImage}
                  />
                </div>
                <div className="service_content">
                  <h2 className="service_heading">{inner.title}</h2>
                  <p className="service_description">{inner.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FourthFoldInner;
