import React from "react";
// import inputOutput from '../../../assets/images/FourthFold/input-output.svg';
import inputOutput from "../../../assets/Vpi/images/FourthFold/input-output.svg";
import "./style.scss";

const FifthFold_v1 = () => {
  return (
    <div className="fifth_fold_v1">
      <div className="container">
        <div className="fifth_wrapper">
          <div className="image_block">
            <img src={inputOutput} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthFold_v1;
