import React, { useState } from "react";
import "./style.scss";
// import ModelImage from "../../assets/Calculator/ModelImage.png";
import { message, Select } from "antd";
import axios, { all } from "axios";
import CustomCarousel from "../CustomSlider";
import ModelImage1 from "../../assets/ContactUs/ModelImage1.webp";
import ModelImage2 from "../../assets/ContactUs/ModelImage2.webp";
import ModelImage3 from "../../assets/ContactUs/ModelImage3.webp";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import "flag-icons/css/flag-icons.min.css"; // Ensure the flag icon CSS is imported

const ContactUsComponent = ({ closeModal, isModal }) => {
  const baseUrlApp = "https://api-leads.flixstock.com";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    callingCode: "",
  });
  const [selectePhoneCode, setSelectedPhoneCode] = useState();
  const clientUrl = window.location.href;

  const countries = () => {
    let allCountries = getCountries().map((countryCode) => {
      return {
        name: countryCode,
        iso2: countryCode.toLowerCase(),
        dialCode: `+${getCountryCallingCode(countryCode)}`,
      };
    });
    console.log(allCountries);
    return allCountries;
  };
  const filterOption = (input, option) => {
    const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedInput, "i");

    const { children } = option;
    return (
      regex.test(option.key) || regex.test(children) || regex.test(option.title)
    );
  };

  const handleCountryChange = (value) => {
    const numericValue = parseInt(value.replace("+", ""));
    setSelectedPhoneCode(numericValue);
    setFormData((prev) => ({ ...prev, callingCode: numericValue }));
  };
  const sliderImages = [
    {
      imgURL: ModelImage1,
      imgAlt: "img-1",
    },
    {
      imgURL: ModelImage2,
      imgAlt: "img-2",
    },
    {
      imgURL: ModelImage3,
      imgAlt: "img-3",
    },
  ];
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    if (typeof value === "string" && !value.trim()) {
      newErrors[name] = `${name} is required`;
    } else if (!value) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };
  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    [
      "firstName",
      "lastName",
      "phoneNumber",
      "email",
      "message",
      "callingCode",
    ].forEach((field) => {
      const value = formData[field];
      if (typeof value === "string") {
        if (!value.trim()) {
          newErrors[field] = `${field} is required`;
        }
      } else if (value == null || value === "") {
        newErrors[field] = `${field} is required`;
      }
    });

    // Validate email format
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = name === "phoneNumber" ? parseInt(value, 10) : value;
    setFormData((prev) => ({ ...prev, [name]: numericValue }));
    validateField(name, numericValue);
  };
  const sendUserDetails = async () => {
    try {
      await axios.post(`${baseUrlApp}/api/limited/v1/inquiry/`, {
        clientUrl: clientUrl,
        formData: formData,
        Identifier: "ImageEdit calculator",
      });
      message.success({
        content: "Submitted Successfully",
        duration: 2,
      });
      if (isModal) {
        closeModal();
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitted(true);
      sendUserDetails();
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phoneNumber: "",
      });
    } else {
      console.log(errors);
    }
  };

  return (
    <div className="contact_us_modal_container">
      <div className="left_wrapper">
        <CustomCarousel>
          {sliderImages.map((image, index) => {
            return (
              <img
                key={index}
                src={image.imgURL}
                alt={image.imgAlt}
                loading="lazy"
              />
            );
          })}
        </CustomCarousel>
      </div>
      <div className="right_wrapper">
        <form onSubmit={handleSubmit}>
          <div className="content_wrapper">
            <div className="form_control">
              <label>First Name</label>
              <input
                placeholder="John"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                name="firstName"
              />
              {errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
            </div>
            <div className="form_control">
              <label>Last Name</label>
              <input
                placeholder="Doe"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
                name="lastName"
              />
              {errors.lastName && (
                <div className="error">{errors.lastName}</div>
              )}
            </div>
            <div className="form_control">
              <label>Email</label>
              <input
                placeholder="example@company.com"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="form_control">
              <label>Phone Number</label>
              <div className="phone_number_wrapper">
                <Select
                  showSearch
                  placeholder="Country Code"
                  optionFilterProp="children"
                  allowClear
                  filterOption={filterOption}
                  onChange={handleCountryChange}
                  value={selectePhoneCode}
                  className="select_country_wrapper"
                >
                  {countries().map((country) => (
                    <Select.Option key={country.iso2} value={country.dialCode}>
                      <span
                        className={`fi fi-${country.iso2}`}
                        style={{ marginRight: "8px" }}
                      />
                      {/* {country.name}  */}({country.dialCode})
                    </Select.Option>
                  ))}
                </Select>
                <input
                  placeholder="+10123456789"
                  type="number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              {errors.phoneNumber && (
                <div className="error">{errors.phoneNumber}</div>
              )}
            </div>
          </div>
          <div className="form_control_textarea">
            <label>Message</label>
            <textarea
              placeholder="Write your message"
              name="message"
              onChange={handleChange}
              value={formData.message}
            />
            {errors.message && <div className="error">{errors.message}</div>}
          </div>
          <div className="action_btn_wrapper">
            <button type="submit">Send Message</button>
          </div>
          <div className="image_wrapper"></div>
        </form>
      </div>
    </div>
  );
};
export default ContactUsComponent;
