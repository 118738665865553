import React, { useEffect, useState } from "react";
import reason1 from "../../../assets/Home/EighthFold/reason1.svg";
import reason2 from "../../../assets/Home/EighthFold/reason2.svg";
import reason3 from "../../../assets/Home/EighthFold/reason3.svg";
import reason4 from "../../../assets/Home/EighthFold/reason4.svg";
import reason5 from "../../../assets/Home/EighthFold/reason5.svg";
import reason6 from "../../../assets/Home/EighthFold/reason6.svg";
import reason7 from "../../../assets/Home/EighthFold/reason7.svg";
import reason8 from "../../../assets/Home/EighthFold/reason8.svg";
import reason9 from "../../../assets/Home/EighthFold/reason9.svg";
import reason10 from "../../../assets/Home/EighthFold/reason10.svg";
import "./style.scss";
import { Modal } from "antd";
import ContactUsComponent from "../../../common/ContactUs";

const EighthFold = () => {
  const [showContact, setShowContact] = useState(false);

  const cardData = [
    {
      id: 1,
      icon: reason1,
      title: "Speed & Real-Time Processing",
    },
    {
      id: 2,
      icon: reason2,
      title: "Real-Time Feedback & Iteration",
    },
    {
      id: 3,
      icon: reason3,
      title: "Scalability",
    },
    {
      id: 4,
      icon: reason4,
      title: "Consistency & Accuracy",
    },
    {
      id: 5,
      icon: reason5,
      title: "Cost Efficiency",
    },
    {
      id: 6,
      icon: reason6,
      title: "Lower Error Rate",
    },
    {
      id: 7,
      icon: reason7,
      title: "Innovation & Competitive Edge",
    },
    {
      id: 8,
      icon: reason8,
      title: "Seamless Integration",
    },
    {
      id: 9,
      icon: reason9,
      title: "Better for High-Volume Customers",
    },
    {
      id: 10,
      icon: reason10,
      title: "Flexibility",
    },
  ];

  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={showContact}
        footer={null}
        width="85%"
        height="90dvh"
        className="contact_us_modal_wrapper"
        onCancel={() => setShowContact(false)}
      >
        <ContactUsComponent
          closeModal={() => setShowContact(false)}
          isModal={true}
        />
      </Modal>
    );
  };

  return (
    <div className="Eighth_fold_container">
      <div className="eight_fold_content">
        <div className="heading">
          <h1>10 reasons to work with us</h1>
        </div>
        <div className="card_content_wrapper">
          <div className="card_content">
            <div className="first_row">
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason1} alt="" loading="lazy" />
                  Speed & Real-Time Processing
                </div>
              </div>
              <div className="zigzag-item second">
                <div className="card_item">
                  <img src={reason2} alt="" loading="lazy" />
                  Real-Time Feedback & Iteration
                </div>
              </div>
            </div>
            <div className="second_row">
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason3} alt="" loading="lazy" />
                  Scalability
                </div>
              </div>
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason4} alt="" loading="lazy" />
                  Consistency & Accuracy
                </div>
              </div>
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason5} alt="" loading="lazy" />
                  Cost Efficiency
                </div>
              </div>
            </div>
            <div className="third_row">
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason6} alt="" loading="lazy" />
                  Lower Error Rate
                </div>
              </div>
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason7} alt="" loading="lazy" />
                  Innovation & Competitive Edge
                </div>
              </div>
            </div>
            <div className="fourth_row">
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason8} alt="" loading="lazy" />
                  Seamless Integration
                </div>
              </div>
            </div>
            <div className="fifth_row">
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason9} alt="" loading="lazy" />
                  Better for High-Volume Customers
                </div>
              </div>
              <div className="zigzag-item">
                <div className="card_item">
                  <img src={reason10} alt="" loading="lazy" />
                  Flexibility
                </div>
              </div>
            </div>
          </div>
          <div className="action_btn_wrapper">
            <button
              className="actionBtn"
              onClick={(e) => {
                e.preventDefault();
                setShowContact(true);
              }}
            >
              Get Started in Minutes
            </button>
          </div>
          {showContact && getContactUsModal()}
        </div>
      </div>
    </div>
  );
};
export default EighthFold;
