import React, { useState } from "react";
import plus from "../../../assets/Home/TwelthFold/plus.svg";
import minus from "../../../assets/Home/TwelthFold/minus.svg";
import "./style.scss";

const TwelthFold = () => {
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState("FlowEdit");

  const handleActive = (text) => {
    setActive(text);
  };

  const handleExpand = (id) => {
    setSelected(id === selected ? null : id);
  };

  const FlowFoldData = [
    {
      id: 1,
      question: "What image formats do you support?",
      answer: "We support JPG, PNG, TIFF, and many other formats.",
    },
    {
      id: 2,
      question: "What types of editing services do you provide?",
      answer: "FlowEdit offers background removal, color correction, cropping, and more.",
    },
    {
      id: 3,
      question: "How secure is my data?",
      answer: "We use state-of-the-art encryption to ensure your images and data are handled securely.",
    },
    {
      id: 4,
      question: "How long does editing take?",
      answer: "Our AI-powered system guarantees turnaround times of under 10 minutes for images requiring basic fully automated AI based editing e.g. clipping, cropping, reframing and resizing b/g change, exporting in different files format, for remainging type of editing tasks, editing specialists are utilized.",
    },
    {
      id: 5,
      question: "Do you review every image?",
      answer: "Yes! Every image is reviewed by a human editor to ensure the highest quality.",
    },
    {
      id: 6,
      question: "Can I share feedback on images",
      answer: "Yes using review portal",
    },
  ];

  const ComprehensiveFoldData = [
    {
      id: 1,
      question: "What types of image editing services do you offer?",
      answer: "We offer a wide range of services, including background removal, ghost mannequin creation, skin retouching, color correction, shadow creation, and product retouching tailored for e-commerce and fashion needs.",
    },
    {
      id: 2,
      question: "Can you handle high-volume image processing?",
      answer: "Yes, we specialize in handling large batches of images with our AI-powered solutions, ensuring quick turnaround times without compromising on quality.",
    },
    {
      id: 3,
      question: "What is ghost mannequin editing?",
      answer: "Ghost mannequin editing removes the mannequin from apparel images, creating a 3D effect that makes clothing appear as though it's being worn, without showing the mannequin.",
    },
    {
      id: 4,
      question: "How does color correction work?",
      answer: "Our color correction service adjusts colors to ensure uniformity and true-to-life presentation across all product images, making them look consistent and professional.",
    },
    {
      id: 5,
      question: "What is clipping path, and why do I need it?",
      answer: "Clipping path creates a precise outline around your product, enabling easy background removal, text wrapping, or further retouching, making your images more versatile for future use.",
    },
    {
      id: 6,
      question: "Can you resize images for different platforms?",
      answer: "Yes, we offer resizing services to ensure your images meet the specific size requirements for e-commerce platforms, social media, or print, without losing quality.",
    },
  ];

  return (
    <div className="twelth_fold_wrapper">
      <div className="content_wrapper">
        <h2 className="question_title">Frequently Asked Question</h2>
        <div className="tab_section">
          <button
            onClick={() => handleActive("FlowEdit")}
            className={active === "FlowEdit" ? "active" : ""}
          >
            FlowEdit<sup>TM</sup>
          </button>
          <button
            onClick={() => handleActive("ComprehensiveEditing")}
            className={active === "ComprehensiveEditing" ? "active" : ""}
          >
            Comprehensive Editing
          </button>
        </div>
        <div className="frequently_asked">
          <div className="questions_answers">
            {active === "FlowEdit" ? FlowFoldData?.map((fold) => {
              return (
                <div className="box" onClick={() => handleExpand(fold.id)}>
                  <div className="heading_wrapper">
                    <h2 className="question">{fold.question}</h2>
                    {selected === fold.id && (
                    <p className="answer">{fold.answer}</p>
                  )}
                  </div>
                  
                  <div
                    className={`icon ${
                      selected === fold.id ? "minus_icon" : "plus_icon"
                    }`}
                  >
                    {selected === fold.id ? (
                      <img src={minus} alt="" />
                    ) : (
                      <img src={plus} alt="" />
                    )}
                  </div>
                </div>
              );
            }): ComprehensiveFoldData?.map((fold) => {
              return (
                <div className="box" onClick={() => handleExpand(fold.id)}>
                  <div className="heading_wrapper">
                    <h2 className="question">{fold.question}</h2>
                    {selected === fold.id && (
                    <p className="answer">{fold.answer}</p>
                  )}
                  </div>
                  
                  <div
                    className={`icon ${
                      selected === fold.id ? "minus_icon" : "plus_icon"
                    }`}
                  >
                    {selected === fold.id ? (
                      <img src={minus} alt="" />
                    ) : (
                      <img src={plus} alt="" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="know_more">
            <button className="know_btn">Know More</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TwelthFold;
