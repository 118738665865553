import React, { useEffect } from "react";
// import videoPlaceholder from "../../../assets/images/FirstFold/video-placeholder.svg";
import videoPlaceholder from "../../../assets/Vpi/images/FirstFold/video-placeholder.svg";
// import videos from "../../../assets/Vpi/video.mp4";
import vpiVideo from "../../../assets/Vpi/video/IE_VPI.mp4";
import "./style.scss";

const SecondFold_v1 = () => {
  const content = [
    {
      id: 1,
      text: "Are you using vendors' or sellers' provided generic images, which come in all styles and sizes? What if you can transform them as per your style guidelines? With ImageEdit, you can replace models, and backgrounds, adjust canvas size and padding, match visual styles, and re-light images, getting them market-ready in hours.",
    },
    {
      id: 2,
      text: "Leveraging cutting-edge segmentation models and generative AI, ImageEdit ensures perfect matting and precise image analysis. We transform any image seamlessly into your brand’s style, improving the browsing experience for your customers on your site.",
    },
    {
      id: 3,
      text: "ImageEdit uses specialized AI tools to transform a demanding process into a quick, automated workflow with minimal effort. Our technology enhances every aspect of your images with precision.",
    },
  ];

  useEffect(() => {
    document.querySelector("video").play();
  }, []);

  return (
    <div className="second_fold_v1">
      <div className="container">
        <div className="second_wrapper">
          <div className="video_placeholder">
            <video
              controls
              poster={videoPlaceholder}
              autoPlay
              muted
              loop
              playsInline
              preload="metadata"
              id="videoId"
              onError={() => console.log("Error loading video")}
            >
              {/* <source src="/video.mp4" type="video/mp4" /> */}
              <source src={vpiVideo} type="video/mp4" />
            </video>
          </div>
          {content.map((content) => {
            return <p className="second_text">{content.text}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default SecondFold_v1;
