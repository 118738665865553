import React from 'react';
import './style.scss';

const FirstFoldInner = () =>{
    return(
        <div className='first_fold_inner'>
            <div className='content'>
                <h2 className='title'>Attain Impeccable Results with Complete Editing Solutions — Engineered for Unrivaled Accuracy</h2>
                <p className='subText'>Our comprehensive editing services guarantee precise, high-quality images, tailored to meet your brand’s standards. Leveraging advanced AI technology and expert craftsmanship, we ensure every detail is perfected for consistent and polished visuals across all platforms.</p>
            </div>
        </div>
    )
}

export default FirstFoldInner;