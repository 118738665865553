import React from "react";
import FlowEdit from "../../components/FlowEdit";
import "./style.scss";
const FlowEditPage = () => {
  return (
    <div className="flowEdit_page">
      <FlowEdit />
    </div>
  );
};

export default FlowEditPage;
