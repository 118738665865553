import React from "react";
import "./style.scss";

const SixthFold = () => {
  const sixthFoldData = [
    {
      id: 1,
      numberStats: "10x",
      descriptionStats: "faster turnaround with AI-powered processing.",
    },
    {
      id: 2,
      numberStats: "50%",
      descriptionStats: "Cost save compared to traditional services.",
    },
    {
      id: 3,
      numberStats: "1000s",
      descriptionStats: "of images edit without losing quality or speed.",
    },
  ];

  return (
    <div className="sixth_fold_wrapper">
      <div className="sixth_fold_features">
        {sixthFoldData?.map((sixth) => {
          return (
            <div className="features_inner">
              <div className="stats_number">{sixth.numberStats}</div>
              <div className="stats_desc">{sixth.descriptionStats}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SixthFold;
