import React, { useState } from "react";
import { message } from "antd";
import axios from "axios";
import "./style.scss";

const ThirteenFold = () => {
  const clientUrl = window.location.href;
  const baseUrlApp = "https://api-leads.flixstock.com";

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!value.trim()) {
      newErrors[name] = `${name} is required`;
    } else {
      // Additional validation for the email field
      if (name === "email" && !emailRegex.test(value)) {
        newErrors[name] = "Invalid email format";
      } else {
        delete newErrors[name];
      }
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Loop through form fields to check for empty values
    ["name", "email", "message"].forEach((field) => {
      if (!formValues[field].trim()) {
        newErrors[field] = `${field} is required`;
      }
    });

    // Validate email format
    if (formValues.email && !emailRegex.test(formValues.email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendUserDetails = async () => {
    try {
      const res = await axios.post(`${baseUrlApp}/api/limited/v1/inquiry/`, {
        clientUrl: clientUrl,
        formData: formValues,
        Identifier: "contact-us home page",
      });
      if (res.status == 200) {
        message.success({
          content: "Submitted Successfully",
          duration: 2,
        });
        setFormValues({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitted(true);
      sendUserDetails();
      console.log(formValues);
    } else {
      console.log(errors);
    }
  };

  return (
    <div className="thirteen_fold_wrapper">
      <div className="contact_us_form">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Name</label>
            <input
              type="text"
              placeholder="Your Full Name"
              className="input_field"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="field">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter E-mail Address"
              className="input_field"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <div className="field_message">
            <label>Message</label>
            <textarea
              placeholder="Write the message..."
              className="message"
              name="message"
              onChange={handleChange}
              value={formValues.message}
            />
            {errors.message && <div className="error">{errors.message}</div>}
          </div>
          <div className="submitBtnWrapper">
            <button type="submit">Send a Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ThirteenFold;
