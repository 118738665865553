import React, { useState } from "react";
// import mainLogo from "../../../assets/images/flixstock-logo.svg";
import mainLogo from "../../../assets/common/Logo.svg";
// import arrowIcon from "../../../assets/images/FirstFold/arrow-icon.svg";
import arrowIcon from "../../../assets/Vpi/images/FirstFold/arrow-icon.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import ContactUsComponent from "../../../common/ContactUs";
const FirstFold_v1 = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        footer={null}
        width="85%"
        height="90dvh"
        className="contact_us_modal_wrapper"
        onCancel={() => setIsModalOpen(false)}
      >
        <ContactUsComponent
          closeModal={() => setIsModalOpen(false)}
          isModal={true}
        />
      </Modal>
    );
  };
  return (
    <div className="first_fold_v1">
      <div className="logo">
        <Link to="/">
          {" "}
          <img src={mainLogo} alt="" />
        </Link>
      </div>
      <div className="video_section">
        <h2 className="title">Vendor-provided images</h2>
        <p className="sub_text">
          The Problem: vendor-provided images (VPI) come in all styles and sizes
          that do not match with your brand's style guide.<br/> This makes your site
          appear cluttered and provides an inconsistent visual identity for your
          visitors.
        </p>
        <p className="sub_text">
          The solution: ImageEdit's suite of tools helps transform images from
          different sources according to your brand's style guide.<br/> Such
          transformed imagery blends well with the rest of the images on your
          website, making the site imagery visually consistent and helping
          improve Customer experience.
        </p>
        <div className="button_section">
          <button onClick={() => setIsModalOpen(true)}>
            GET IN TOUCH
            <img src={arrowIcon} alt="" />
          </button>
        </div>
      </div>
      {isModalOpen && getContactUsModal()}
    </div>
  );
};

export default FirstFold_v1;
