import React from "react";
import "./style.scss";
import Vpi from "../../components/VPI";
const VpiPage = () => {
  return (
    <div className="vpi_page_wrapper">
      <Vpi />
    </div>
  );
};
export default VpiPage;
