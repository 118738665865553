import React, { useState } from "react";
import BeforeAfterSlider from "../../../common/BeforeAfter";
import modelSkin1 from "../../../assets/FlowEdit/SecondFoldInner/model_skin1.webp";
import modelSkin2 from "../../../assets/FlowEdit/SecondFoldInner/model_skin2.webp";
import productRetouch1 from "../../../assets/FlowEdit/SecondFoldInner/product_retouch1.webp";
import productRetouch2 from "../../../assets/FlowEdit/SecondFoldInner/product_retouch2.webp";
import ghostImage1 from "../../../assets/FlowEdit/SecondFoldInner/ghost_before.webp";
import ghostImage2 from "../../../assets/FlowEdit/SecondFoldInner/ghost_after.webp";
import flatlayCreation1 from "../../../assets/FlowEdit/SecondFoldInner/flatlay_creation1.webp";
import flatlayCreation2 from "../../../assets/FlowEdit/SecondFoldInner/flatlay_creation2.webp";
import "./style.scss";
import { Modal } from "antd";
import ContactUsComponent from "../../../common/ContactUs";
const SecondFoldInner = () => {
  const [showContact, setShowContact] = useState(false);

  const foldContent = [
    {
      id: 1,
      beforeImage: modelSkin1,
      afterImage: modelSkin2,
      title: "Model & Skin Retouching",
      description:
        "Achieve flawless, natural-looking skin by removing blemishes, smoothing out wrinkles, and refining model images for high-quality fashion photography. Ensure your models look their best while maintaining a lifelike appearance.",
    },
    {
      id: 2,
      beforeImage: productRetouch1,
      afterImage: productRetouch2,
      title: "Product Retouching",
      description:
        "Refine product images by correcting imperfections, adjusting lighting, and enhancing details, delivering clean and polished visuals ready for e-commerce platforms.",
    },
    {
      id: 3,
      beforeImage: ghostImage1,
      afterImage: ghostImage2,
      title: "Ghost Creation",
      description:
        "Remove mannequins from clothing images to create the popular invisible mannequin effect, giving your apparel a professional, 3D appearance without distractions.",
    },
    {
      id: 4,
      beforeImage: flatlayCreation1,
      afterImage: flatlayCreation2,
      title: "Flat-lay Creation",
      description:
        "Design and enhance flat-lay images, ensuring each product is perfectly arranged, clear, and visually appealing for top-down photography used in fashion and e-commerce.",
    },
  ];

  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={showContact}
        footer={null}
        width="85%"
        height="90dvh"
        className="contact_us_modal_wrapper"
        onCancel={() => setShowContact(false)}
      >
        <ContactUsComponent
          closeModal={() => setShowContact(false)}
          isModal={true}
        />
      </Modal>
    );
  };
  return (
    <div className="second_fold_inner">
      <div className="slider_boxes">
        {foldContent?.map((content) => {
          return (
            <div className={`box ${content.id % 2 === 0 ? "reverse" : ""}`}>
              <div className="image_box">
                <BeforeAfterSlider
                  beforeImage={content.beforeImage}
                  afterImage={content.afterImage}
                />
              </div>
              <div className="content_box">
                <h2 className="title">{content.title}</h2>
                <p className="description">{content.description}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowContact(true);
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>
          );
        })}

        {showContact && getContactUsModal()}
      </div>
    </div>
  );
};

export default SecondFoldInner;
