import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import Layout from './common/Layout';

function App() {
  return (
        <Layout/>
  );
}

export default App;
