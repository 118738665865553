import React from "react";
import all from "../../../assets/Home/SeventhFold/all.webp";
import seamless from "../../../assets/Home/SeventhFold/seamless.webp";
import "./style.scss";

const TwentyFold = () => {
  const twentyFoldData = [
    {
      id: 1,
      imgUrl: seamless,
      title: "Seamless Workflow Integration",
      title2: "",
      description:
        "Enhance your workflow efficiency with seamless API integration or easy plug-and-play Auto Fetch & Push FTP integration. Optimize your processes for faster and more reliable image handling without disruption.",
    },
    {
      id: 2,
      imgUrl: all,
      title: "All Connected -",
      title2: " Easily connect your tech stack with our developer API.",
      description:
        "Our Web Service (WS) seamlessly integrates into various systems, offering full functionality like the website, allowing easy job instructions, status monitoring, and rejection management for streamlined workflows.",
    },
  ];

  return (
    <div className="twenty_fold_wrapper">
      <div className="fold_content">
        {twentyFoldData.map((fold) => {
          return (
            <div className="workflow_container">
              <div className="image_box">
                <img src={fold.imgUrl} alt="" />
              </div>
              <h2 className="fold_title">
                {fold.title}
                {fold.title2 !== "" ? (
                  <>
                    <span>{fold.title2}</span>
                  </>
                ) : (
                  ""
                )}
              </h2>
              <p className="fold_description">{fold.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TwentyFold;
