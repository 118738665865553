import React, { useRef, useEffect, useState } from "react";
import "./style.scss";

const BeforeAfterSlider = ({ beforeImage, afterImage }) => {
  const sliderRef = useRef(null);
  const beforeRef = useRef(null);
  const resizerRef = useRef(null);
  const [active, setActive] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(50); // Default to middle

  useEffect(() => {
    const slider = sliderRef.current;
    const beforeImage = beforeRef.current.getElementsByTagName("img")[0];

    const handleResize = () => {
      beforeImage.style.width = `${slider.offsetWidth}px`;
    };

    handleResize(); // Initial resize
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const startSlide = () => {
    setActive(true);
    resizerRef.current.classList.add("resize");
  };

  const stopSlide = () => {
    setActive(false);
    resizerRef.current.classList.remove("resize");
  };

  const handleMove = (e) => {
    if (!active) return;
    const slider = sliderRef.current;
    let x = e.pageX || e.touches[0].pageX;
    x -= slider.getBoundingClientRect().left;
    slideIt(x);
    e.preventDefault();
  };

  const slideIt = (x) => {
    const slider = sliderRef.current;
    const transform = Math.max(0, Math.min(x, slider.offsetWidth));
    setSliderPosition((transform / slider.offsetWidth) * 100);
  };

  return (
    <div
      className="slider-container"
      ref={sliderRef}
      onMouseMove={handleMove}
      onTouchMove={handleMove}
    >
      <div
        className="before-image"
        ref={beforeRef}
        style={{ width: `${sliderPosition}%` }}
      >
        <img src={beforeImage} alt="Before" loading="lazy" />
      </div>
      <div className="after-image">
        <img src={afterImage} alt="After" loading="lazy" />
      </div>
      <div
        className="slider-handle"
        ref={resizerRef}
        style={{ left: `${sliderPosition}%` }}
        onMouseDown={startSlide}
        onTouchStart={startSlide}
        onMouseUp={stopSlide}
        onTouchEnd={stopSlide}
      />
    </div>
  );
};

export default BeforeAfterSlider;
