import React from "react";
import "./style.scss";
import ContactUs from "../../components/ContactUs";
const ContactUsPage = () => {
  return (
    <div className="contact_us_page_wrapper">
      <ContactUs />
    </div>
  );
};
export default ContactUsPage;
