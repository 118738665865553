import ContactUsPage from "../pages/ContactUsPage";
import FlowEditPage from "../pages/FlowEditPage";
import HomePage from "../pages/HomePage";
import VpiPage from "../pages/VpiPage";

export const routesList = [
  {
    path: `/`,
    title: "ImageEdit",
    exact: true,
    protected: true,
    component: HomePage,
  },
  {
    path: `/:scrollTo`,
    title: "ImageEdit",
    exact: true,
    protected: true,
    component: HomePage,
  },
  {
    path: `/comprehensive-editing`,
    title: "ImageEdit",
    exact: true,
    protected: true,
    component: FlowEditPage,
  },
  {
    path: `/contact-us`,
    title: "ImageEdit",
    exact: true,
    protected: true,
    component: ContactUsPage,
  },
  {
    path: `/vpi`,
    title: "ImageEdit",
    exact: true,
    protected: true,
    component: VpiPage,
  },
];
