import React from "react";
import photography from "../../../assets/Home/ThirdFold/photography.webp";
import fashion from "../../../assets/Home/ThirdFold/fashion.webp";
import market from "../../../assets/Home/ThirdFold/market.webp";
import "./style.scss";

const ThirdFold = () => {
  const flowEditData = [
    {
      id: 1,
      imgUrl: fashion,
      title: "Fashion Retailers",
      subTitle: "",
      subText1: "Need:",
      subText1Desc: "Fast, consistent, large-scale product photo editing.",
      subText2: "Benefit:",
      subText2Desc:
        "Enjoy under 10-minute turnarounds, consistent quality, and lower costs, ensuring streamlined catalog management.",
      isHavingBackground: true,
    },
    {
      id: 2,
      imgUrl: market,
      title: "Marketplaces",
      subTitle: " (e.g., Amazon, Etsy, eBay)",
      subText1: "Need:",
      subText1Desc:
        "Efficient processing of thousands of images from multiple sellers.",
      subText2: "Benefit:",
      subText2Desc:
        "FlowEdit offers a faster, affordable solution for maintaining consistent style across listings.",
      isHavingBackground: true,
    },
    {
      id: 3,
      imgUrl: photography,
      title: "Photography Studios",
      subText1: "Need:",
      subText1Desc: "High-volume, efficient post-processing.",
      subText2: "Benefit:",
      subText2Desc:
        "FlowEdit reduces manual editing, freeing studios to focus on more creative tasks while ensuring professional, consistent output.",
      isHavingBackground: true,
    },
  ];

  return (
    <div className="third_fold_wrapper">
      <div className="flow_edit">
        <h2 className="section_title">
          FlowEdit™ is designed keeping you in mind
        </h2>
        <div className="features">
          {flowEditData?.map((flow) => {
            return (
              <div className="features_list">
                <div className="list_image">
                  <img src={flow.imgUrl} alt="" loading="lazy" />
                </div>
                <h3 className="list_title">
                  {flow.title}
                  <span className="sub_title">{flow.subTitle}</span>
                </h3>
                <div className="inner_list">
                  <div className="need">
                    <div className="need_title primary">{flow.subText1}</div>
                    <div className="need_desc">{flow.subText1Desc}</div>
                  </div>
                  <div className="need">
                    <div className="need_title secondary">{flow.subText2}</div>
                    <div className="need_desc">{flow.subText2Desc}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ThirdFold;
