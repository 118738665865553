import React from "react";
import "./style.scss";
import Image1 from "../../../assets/Home_TenthFold/Image1.png";
import Image2 from "../../../assets/Home_TenthFold/Image2.png";
import Image3 from "../../../assets/Home_TenthFold/Image3.png";
import Image4 from "../../../assets/Home_TenthFold/Image4.png";
import Image5 from "../../../assets/Home_TenthFold/Image5.png";
import Image6 from "../../../assets/Home_TenthFold/Image6.png";
import InfiniteLooper from "./InfiniteLoop";

const TenthFold = () => {
  const imageData = [Image1, Image2, Image3, Image4, Image5, Image6];
  return (
    <div className="tenthFold_main_container">
      <div className="heading">
        <h1>
          Trusted by over 200+ fashion retailers and e-commerce platforms.
        </h1>
      </div>
      <div className="image-section">
        <section>
          <div className="corousel">
            <InfiniteLooper speed={20} direction={"left"}>
              {imageData.map((image, id) => {
                return (
                  <div className="card_wrapper">
                    <div className="image_wrapper">
                      <img src={image} alt={`${id + 1}`} />
                    </div>
                  </div>
                );
              })}
            </InfiniteLooper>
          </div>
        </section>
      </div>
    </div>
  );
};
export default TenthFold;
