import React from "react";
import "./style.scss";
import BeforeAfterSlider from "../../../common/BeforeAfter";
import bgRemove1 from "../../../assets/FlowEdit/ThirdFoldInner/bg-remove1.webp";
import bgRemove2 from "../../../assets/FlowEdit/ThirdFoldInner/bg-remove2.webp";
import bgChange1 from "../../../assets/FlowEdit/ThirdFoldInner/bg-change1.webp";
import bgChange2 from "../../../assets/FlowEdit/ThirdFoldInner/bg-change2.webp";
import bgRetain1 from "../../../assets/FlowEdit/ThirdFoldInner/bg-retain1.webp";
import bgRetain2 from "../../../assets/FlowEdit/ThirdFoldInner/bg-retain2.webp";

const ThirdFoldInner = () => {
  const foldInnerData = [
    {
      id: 1,
      beforeImage: bgRemove1,
      afterImage: bgRemove2,
      title: "Background Remove",
      description:
        "Effortlessly remove unwanted backgrounds, isolating your products to make them stand out and ensuring they’re ready for any platform or marketing material.",
    },

    {
      id: 2,
      beforeImage: bgRetain1,
      afterImage: bgRetain2,
      title: "Background Retain",
      description:
        "Keep and enhance original backgrounds while improving image quality, adjusting lighting, and making subtle refinements to bring out the best in your visuals.",
    },

    {
      id: 3,
      beforeImage: bgChange1,
      afterImage: bgChange2,
      title: "Background Change",
      description:
        "Swap out existing backgrounds with custom colors, textures, or designs to match your branding or create a unique, engaging presentation for your products.",
    },
  ];

  return (
    <div className="third_fold_inner">
      <div className="fold_content">
        <h2 className="heading">Image Background Service</h2>
        <div className="services">
          {foldInnerData?.map((inner) => {
            return (
              <div className="service_box">
                <div className="service_image">
                  <BeforeAfterSlider
                    beforeImage={inner.beforeImage}
                    afterImage={inner.afterImage}
                  />
                </div>
                <div className="service_content">
                  <h2 className="service_heading">{inner.title}</h2>
                  <p className="service_description">{inner.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ThirdFoldInner;
