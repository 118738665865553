import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import EmailLogo from "../../assets/common/Email.webp";
import FacebookLogo from "../../assets/common/Facebook.webp";
import Logo from "../../assets/common/Logo.svg";
import Instagram from "../../assets/common/Instagram.webp";
import LinkedIn from "../../assets/common/LinkedIn.webp";
import Mark from "../../assets/common/Mark.webp";
import Phone from "../../assets/common/Phone.webp";
import YoutubeLogo from "../../assets/common/YouTube.webp";
import TwitterLogo from "../../assets/common/Twitter.webp";
import ThirteenFold from "../../components/Home/ThirteenFold";
import scrollIcon from "../../assets/top-chevron-arrow-round-outline-icon.svg";

const Footer = () => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const links = [
    {
      id: 1,
      heading: "Product",
      links: [
        { title: "FlowEdit", href: "", suffix: "TM" },
        {
          title: "Comprehensive Editing",
          href: "/comprehensive-editing",
          suffix: "",
        },
        {
          title: "Remove Model Background",
          href: "https://modelclipper.imageedit.ai/",
          suffix: "",
        },
        {
          title: "VPI",
          href: "/vpi",
          suffix: "",
        },
      ],
    },
    {
      id: 2,
      heading: "Support",
      links: [
        // { title: "About", href: "", suffix: "" },
        { title: "Contact us", href: "/contact-us", suffix: "" },
        // { title: "Careers", href: "", suffix: "" },
        // { title: "Culture", href: "", suffix: "" },
        // { title: "Blog", href: "", suffix: "" },
      ],
    },
    // {
    //   id: 3,
    //   heading: "Support",
    //   links: [
    //     { title: "Getting Started", href: "", suffix: "" },
    //     { title: "Help Center", href: "", suffix: "" },
    //     { title: "Report a bug", href: "", suffix: "" },
    //     { title: "Chat Support", href: "", suffix: "" },
    //   ],
    // },
  ];
  const toggleVisibility = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer_main_wrapper">
      <div className="footer_content">
        {/* <ThirteenFold/>  */}
        <div className="footer_upper_section">
          <div className="first_wrapper">
            <div className="logo_wrapper">
              <img
                src={Logo}
                alt="logo"
                onClick={() => navigate("/")}
                loading="lazy"
              />
            </div>
            {/* <div className="description">
              Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
            </div>
            <div className="social_main_wrapper">
              <Link to="" className="social_wrapper">
                <img src={FacebookLogo} alt="fb" />
              </Link>
              <Link to="" className="social_wrapper">
                <img src={TwitterLogo} alt="tw" />
              </Link>
              <Link to="" className="social_wrapper">
                <img src={Instagram} alt="ig" />
              </Link>
              <Link to="" className="social_wrapper">
                <img src={LinkedIn} alt="lIn" />
              </Link>
              <div className="social_wrapper">
                <img src={YoutubeLogo} alt="yt" />
              </div>
            </div> */}
          </div>
          {links.map((link, index) => {
            return (
              <div className="links_container">
                <div className="heading">
                  <h3>{link.heading}</h3>
                </div>
                <div className="links_wrapper">
                  {link.links.map((links, index) => {
                    return (
                      <Link to={links.href}>
                        {links.title}
                        {links.suffix && <sup>{links.suffix}</sup>}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="contact_us_wrapper">
            <div className="heading">
              <h3>Contact Us</h3>
            </div>
            <div className="info_container">
              <div className="info">
                <div className="image_wrapper">
                  <img src={EmailLogo} alt="email" loading="lazy" />
                </div>
                <span>
                  <Link to="mailto:contact@imageedit.ai">
                    contact@imageedit.ai
                  </Link>
                </span>
              </div>
              {/* <div className="info">
                <div className="image_wrapper">
                  <img src={Phone} alt="phone" />
                </div>
                <span>(414) 000 - 1234</span>
              </div> */}
              {/* <div className="info">
                <div className="image_wrapper">
                  <img src={Mark} alt="location Mark" />
                </div>
                <span>794 ABC Poland, 94102</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="break" />
        <div className="footer_lower_section">
          <div className="left_wrapper">Copyright © 2024 ImageEdit.ai</div>
          {/* <div className="right_wrapper">
            <span className="text">All Rights Reserved | </span>
            <span>
              <Link to="">Terms and Conditions</Link>{" "}
            </span>
            |
            <span>
              {" "}
              <Link to="">Privacy Policy</Link>
            </span>
          </div> */}
        </div>
        {isVisible && (
          <div className="scroll_to_top" onClick={handleScrollToTop}>
            <svg
              fill="#fff"
              height="30"
              viewBox="0 0 20 20"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.20694 12.2676C3.92125 11.9677 3.93279 11.493 4.23271 11.2073L9.48318 6.20597C9.77285 5.93004 10.2281 5.93004 10.5178 6.20597L15.7682 11.2073C16.0681 11.493 16.0797 11.9677 15.794 12.2676C15.5083 12.5676 15.0336 12.5791 14.7336 12.2934L10.0005 7.78483L5.26729 12.2934C4.96737 12.5791 4.49264 12.5676 4.20694 12.2676Z"
                fill="#fff"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};
export default Footer;
