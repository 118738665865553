import React from "react";
import "./style.scss";
import BeforeAfterSlider from "../../../common/BeforeAfter";
import clipping from "../../../assets/FlowEdit/EighthFoldInner/clipping_path.webp";
import cropping from "../../../assets/FlowEdit/EighthFoldInner/cropping.webp";
import resizing from "../../../assets/FlowEdit/EighthFoldInner/resizing.webp";
import eyewearRetouch1 from "../../../assets/FlowEdit/EighthFoldInner/eyewear_retouch1.webp";
import eyewearRetouch2 from "../../../assets/FlowEdit/EighthFoldInner/eyewear_retouch2.webp";
import jewelryRetouch1 from "../../../assets/FlowEdit/EighthFoldInner/jewelry_retouch1.webp";
import jewelryRetouch2 from "../../../assets/FlowEdit/EighthFoldInner/jewelry_retouch2.webp";
import accessoriesRetouch1 from "../../../assets/FlowEdit/EighthFoldInner/accessories_retouch1.webp";
import accessoriesRetouch2 from "../../../assets/FlowEdit/EighthFoldInner/accessories_retouch2.webp";
import swatch from "../../../assets/FlowEdit/EighthFoldInner/swatch.webp";
import exportImage from "../../../assets/FlowEdit/EighthFoldInner/export.webp";

const SeventhFoldInner = () => {
  const SeventhInnerData = [
    {
      id: 1,
      image: clipping,
      title: "Clipping Path",
      description:
        "Create versatile image files with precise clipping paths, making them ready for future editing tasks, or further retouching by your internal team.",
      isBeforeAfter: false,
    },

    {
      id: 2,
      image: cropping,
      title: "Cropping",
      description:
        "Adjust the framing of your images to focus on key elements, ensuring your product is the star of the image and meets platform-specific requirements.",
      isBeforeAfter: false,
    },

    {
      id: 3,
      image: resizing,
      title: "Resizing",
      description:
        "Resize your images to meet different platform specifications while maintaining quality, ensuring your visuals are optimized for various formats.",
      isBeforeAfter: false,
    },
    {
      id: 4,
      beforeImage: eyewearRetouch1,
      afterImage: eyewearRetouch2,
      title: "Eyewear Retouching",
      description:
        "Perfect images of glasses and sunglasses by removing reflections, enhancing clarity, and ensuring each frame is displayed beautifully.",
      isBeforeAfter: true,
    },

    {
      id: 5,
      beforeImage: jewelryRetouch1,
      afterImage: jewelryRetouch2,
      title: "Jewelry & Watch Retouching",
      description:
        "Enhance the shine, sparkle, and details of jewelry and watches, ensuring every piece looks luxurious and flawless in your images.",
      isBeforeAfter: true,
    },

    {
      id: 6,
      beforeImage: accessoriesRetouch1,
      afterImage: accessoriesRetouch2,
      title: "Accessories Retouching",
      description:
        "Refine images of accessories like bags, belts, and hats, removing imperfections and ensuring they look polished and professional.",
      isBeforeAfter: true,
    },
    {
      id: 7,
      image: swatch,
      title: "Swatch Extraction",
      description:
        "Extract fabric swatches to showcase details and textures, offering close-up views that highlight the material quality of your products.",
      isBeforeAfter: false,
    },

    {
      id: 8,
      image: exportImage,
      title: "Multiple Export Setting",
      description:
        "Export your images with layered PSD files, including masks and vector paths for easy further editing. The export also includes both original and edited versions for quick comparisons.",
      isBeforeAfter: false,
    },
  ];

  return (
    <div className="Seventh_fold_inner">
      <div className="fold_content">
        <div className="services">
          {SeventhInnerData?.map((inner) => {
            return (
              <div className="service_box">
                <div className="service_image">
                  {inner.isBeforeAfter ? (
                    <BeforeAfterSlider
                      beforeImage={inner.beforeImage}
                      afterImage={inner.afterImage}
                    />
                  ) : (
                    <img src={inner.image} alt="" loading="lazy" />
                  )}
                </div>
                <div className="service_content">
                  <h2 className="service_heading">{inner.title}</h2>
                  <p className="service_description">{inner.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeventhFoldInner;
