import React, { useEffect, useState } from "react";
import "./style.scss";
import before from "../../../assets/Home/FirstFold/before.webp";
import after from "../../../assets/Home/FirstFold/after.webp";
import colorBefore from "../../../assets/Home/FirstFold/color-before.webp";
import colorAfter from "../../../assets/Home/FirstFold/color-after.webp";
import shadowBefore from "../../../assets/Home/FirstFold/shadow-before.webp";
import shadowAfter from "../../../assets/Home/FirstFold/shadow-after.webp";
import arrow from "../../../assets/Home/FirstFold/uparrow.webp";
import arrowLeft from "../../../assets/Home/FirstFold/arrow_left.webp";
import arrowRight from "../../../assets/Home/FirstFold/arrow_right.webp";
import image1 from '../../../assets/Home/FirstFold/slider1.webp';
import image2 from '../../../assets/Home/FirstFold/slider2.webp';
import image3 from '../../../assets/Home/FirstFold/slider3.webp';
import image4 from '../../../assets/Home/FirstFold/slider4.webp';
import image5 from '../../../assets/Home/FirstFold/slider5.webp';

import { useNavigate } from "react-router-dom";

const FirstFold = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();

  const images = [
    {
      id: 1,
      mainImage: image1,
    },
    {
      id: 2,
      mainImage: image2,
    },
    {
      id: 3,
      mainImage: image3,
      className: 'slide-3'
    },
    {
      id: 4,
      mainImage: image4,
      className: 'slide-4'
    },
    {
      id: 5,
      mainImage: image5,
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 2000);
    return () => clearInterval(slideInterval);
  }, [images.length]);

  const flowEditRedirection = () => {
    const flowEdit = document.getElementById("flow_edit");
    if (flowEdit) {
      flowEdit.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.pathname === "/") {
      const flowEdit = document.getElementById("flow_edit");
      if (flowEdit) {
        flowEdit.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/scrollTo=flow_edit");
    }
  };

  return (
    <div className="first_fold_main_container">
      <div className="first_fold_content_wrapper">
        <div className="left_wrapper">
          <h1>
            Experience Real-Time Image Editing - Ready in{" "}
            <span>10 Minutes </span> as low as
            <span> ¢10/Image*</span>
          </h1>
          <h2>
            Harnessing the Power of AI and Human Expertise for Unmatched Speed,
            Consistency, and Superior Quality.
          </h2>
          <div className="tryBtn_wrapper">
            <button className="tryBtn" onClick={() => flowEditRedirection()}>
              Try FlowEdit
              <img src={arrow} alt="arrow" loading="lazy" />
            </button>
          </div>
          <div className="description">
            *Includes <span>Background Removal </span>and{" "}
            <span>
              {" "}
              change, Cropping, Reframing & Resizing, Multiple Export Settings
            </span>{" "}
            for resolutions and formats
          </div>
        </div>
        <div className="right_wrapper">
          <div className="slider">
            <div className="image_box">
          {images.map((image, index) => (
              <img
                key={image.id}
                src={image.mainImage}
                alt={`Slide ${image.id}`}
                className={`${index === currentSlide ? 'active' : ''} ${image.className || ''}`}
              />
            ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstFold;
