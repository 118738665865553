import React from "react";
import "./style.scss";
import ContactUsComponent from "../../common/ContactUs";
const ContactUs = () => {
  return (
    <div className="contact_us_wrapper_container">
      <div className="content">
        <div className="heading_wrapper">
          <h1>Contact Us</h1>
          <h5>Any question or remarks? Just write us a message!</h5>
        </div>
        <div className="contact_us">
          <ContactUsComponent isModal={false} />
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
