import React, { useState } from "react";
import "./style.scss";

const FifthFold = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const fifthFoldData = [
    {
      id: 1,
      column1: "Editing Speed",
      column2: "Slower, often taking hours to days per batch of images",
      column3: "Real-time or within 10 minutes for large batches",
    },
    {
      id: 2,
      column1: "Cost",
      column2: "High labor costs due to manual effort by skilled editors",
      column3: "Lower cost due to AI handling bulk of the editing process",
    },
    {
      id: 3,
      column1: "Scalability",
      column2: "Limited by the number of available human editors",
      column3: "Highly scalable—can handle large volumes with minimal delay",
    },
    {
      id: 4,
      column1: "Turnaround Time",
      column2: "Highly variable, depending on the volume and complexity",
      column3: "Fast and predictable—delivers consistently in under 10 minutes",
    },
    {
      id: 5,
      column1: "Quality Control",
      column2: "Fully manual, relying on human editors to ensure accuracy",
      column3: "Automated QC by AI followed by human review for high standards",
    },
    {
      id: 6,
      column1: "Customization",
      column2: "Custom edits depend on clear communication with editors",
      column3: "Customizations applied automatically by AI, based on presets",
    },
    {
      id: 7,
      column1: "Consistency",
      column2: "Can be inconsistent due to human errors and varying styles",
      column3: "High consistency—AI applies uniform standards across images",
    },
    {
      id: 8,
      column1: "Error Rate",
      column2: "Prone to human error, especially with repetitive tasks",
      column3:
        "Lower error rate—AI reduces human fatigue and repetitive mistakes",
    },
    {
      id: 9,
      column1: "Customer Interaction",
      column2: "More back-and-forth communication needed for feedback",
      column3:
        "Minimal interaction—feedback is incorporated directly into the system",
    },
    {
      id: 10,
      column1: "Real-Time Editing",
      column2: "Not possible—manual processes take time to complete",
      column3: "Supports real-time continuous editing as images are captured",
    },
    {
      id: 11,
      column1: "File Transfer & Storage",
      column2: "Often involves manual uploads/downloads, with delays",
      column3: "Instant, automated uploads and downloads via cloud storage",
    },
    {
      id: 12,
      column1: "Revisions",
      column2: "Time-consuming and can lead to longer turnaround",
      column3:
        "Fast revisions based on feedback, with AI making adjustments quickly",
    },
    {
      id: 13,
      column1: "Labor Dependency",
      column2: "Dependent on available workforce, time zones, and fatigue",
      column3: "Automated system with minimal human intervention needed",
    },
    {
      id: 14,
      column1: "Flexibility",
      column2: "Can handle complex tasks but at a slower pace",
      column3:
        "Highly flexible for standard edits, but complex tasks may still need human input",
    },
  ];

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="fifth_fold_wrapper">
      <div className="manual_editing">
        <h2 className="editing_title">
          Manual Editing Slows You Down – FlowEdit™ Speeds You Up
        </h2>
        <p className="sub_title">
          Traditional image editing services are slow, labor-intensive, and
          costly. At ImageEdit.ai, we’ve automated the process to save you time
          and money.
        </p>
        <div className="content_table">
          <table className="listing_table">
            <tr>
              <th>Aspect</th>
              <th>Manual Editing Services</th>
              <th className="thirdColumn">FlowEdit (AI-Powered Editing)</th>
            </tr>
            {isExpanded
              ? fifthFoldData?.map((table) => {
                  return (
                    <tr>
                      <td className="firstColumn">{table.column1}</td>
                      <td>{table.column2}</td>
                      <td className="thirdColumn">{table.column3}</td>
                    </tr>
                  );
                })
              : fifthFoldData?.slice(0, 4)?.map((table) => {
                  return (
                    <tr>
                      <td className="firstColumn">{table.column1}</td>
                      <td>{table.column2}</td>
                      <td className="thirdColumn">{table.column3}</td>
                    </tr>
                  );
                })}
          </table>
          <div className="show_button">
            <button onClick={toggleExpanded}>
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          </div>
        </div>

        <div className="mobile_content_table">
          {isExpanded ? fifthFoldData.map((foldData, index) => {
            return (
              <div className="content_card">
                <div className="heading">
                  Aspect: <span> {foldData.column1}</span>
                </div>
                <div className="card_content">
                  <div className="left_wrapper">
                    <div className="content_heading">
                      Manual Editing Services{" "}
                    </div>
                    <div className="content">{foldData.column2} </div>
                  </div>
                  <div className="right_wrapper">
                    <div className="content_heading">
                      FlowEdit (AI-Powered Editing)
                    </div>
                    <div className="content">{foldData.column3} </div>
                  </div>
                </div>
              </div>
            );
          }):fifthFoldData.slice(0, 4)?.map((foldData, index) => {
            return (
              <div className="content_card">
                <div className="heading">
                  Aspect: <span> {foldData.column1}</span>
                </div>
                <div className="card_content">
                  <div className="left_wrapper">
                    <div className="content_heading">
                      Manual Editing Services{" "}
                    </div>
                    <div className="content">{foldData.column2} </div>
                  </div>
                  <div className="right_wrapper">
                    <div className="content_heading">
                      FlowEdit (AI-Powered Editing)
                    </div>
                    <div className="content">{foldData.column3} </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="show_button">
            <button onClick={toggleExpanded}>
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthFold;
