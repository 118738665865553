import React, { useState } from "react";
import icon1 from "../../../assets/Home/SecondFold/icon1.svg";
import icon2 from "../../../assets/Home/SecondFold/icon2.svg";
import icon3 from "../../../assets/Home/SecondFold/icon3.svg";
import icon4 from "../../../assets/Home/SecondFold/icon4.svg";
import listIcon1 from "../../../assets/Home/SecondFold/listIcon1.svg";
import listIcon2 from "../../../assets/Home/SecondFold/listIcon2.svg";
import listIcon3 from "../../../assets/Home/SecondFold/listIcon3.svg";
import listIcon4 from "../../../assets/Home/SecondFold/listIcon4.svg";
import listIcon5 from "../../../assets/Home/SecondFold/listIcon5.svg";
import listIcon6 from "../../../assets/Home/SecondFold/listIcon6.svg";
import listIcon7 from "../../../assets/Home/SecondFold/listIcon7.svg";
import listIcon8 from "../../../assets/Home/SecondFold/listIcon8.svg";
import listIcon9 from "../../../assets/Home/SecondFold/listIcon9.svg";
import listIcon10 from "../../../assets/Home/SecondFold/listIcon10.svg";
import listIcon11 from "../../../assets/Home/SecondFold/listIcon11.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import ContactUsComponent from "../../../common/ContactUs";

const SecondFold = () => {
  const [active, setActive] = useState("FlowEdit");
  const [showActive, setShowActive] = useState("Get Started in Minutes");
  const [showContact, setShowContact] = useState(false);


  const handleActive = (text) => {
    setActive(text);
  };

  const handleShowActive = (text) => {
    setShowActive(text);
  };

  const flowData = [
    {
      id: 1,
      title:
        "Experience Continuous edits with FlowEdit™ — designed for total peace of mind",
      subTitle:
        "FlowEdit™ seamlessly integrates with your workflow, editing each image within",
      subTitle2: "10 minutes",
      subTitle3: "as soon as it’s captured",
      features: [
        {
          id: 1,
          icon: icon1,
          title: "AI-Powered Efficiency",
          text: "Our technology ensures you get high-quality images in less than 10 minutes.",
        },
        {
          id: 2,
          icon: icon2,

          title: "Cost-Effective",
          text: "AI-powered editing allows us to offer prices much lower than traditional editing services.",
        },
        {
          id: 3,
          icon: icon3,
          title: "Human QC for Every Image",
          text: "While AI handles the bulk of the work, a human expert reviews every image to ensure perfection.",
        },
        {
          id: 4,
          icon: icon4,
          title: "Real-Time Processing",
          text: "We integrate with your in-house studio workflow for continuous, real-time editing",
        },
      ],
    },
  ];

  const comprehensiveData = [
    {
      id: 1,
      title:
        "Achieve Flawless Results with Comprehensive Editing — Crafted for Absolute Precision",
      subTitle: "Our",
      subTitle2: "Comprehensive Editing",
      subTitle3:
        "service delivers precise, high-quality image edits with a focus on customization and accuracy to meet your specific needs.",
      features: [
        {
          id: 1,
          icon: listIcon1,
          title: "Background Removal",
        },
        {
          id: 2,
          icon: listIcon2,
          title: "Shadow Creation for Models",
        },
        {
          id: 3,
          icon: listIcon3,
          title: "Dust & Stain removal",
        },
        {
          id: 4,
          icon: listIcon4,
          title: "Skin Retouching",
        },
        {
          id: 5,
          icon: listIcon5,
          title: "Tones and Colors Harmonization",
        },
        {
          id: 6,
          icon: listIcon6,
          title: "Background Cleaning",
        },
        {
          id: 7,
          icon: listIcon7,
          title: "Reframing and Resizing",
        },
        {
          id: 8,
          icon: listIcon8,
          title: "Face Cropping",
        },
        {
          id: 9,
          icon: listIcon9,
          title: "Ghost Mannequin Creation",
        },
        {
          id: 10,
          icon: listIcon10,
          title: "Fabric Swatch Extraction",
        },
        {
          id: 11,
          icon: listIcon11,
          title: "Multiple Export Settings",
        },
      ],
    },
  ];

  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={showContact}
        footer={null}
        width="85%"
        height="90dvh"
        className="contact_us_modal_wrapper"
        onCancel={() => setShowContact(false)}
      >
        <ContactUsComponent
          closeModal={() => setShowContact(false)}
          isModal={true}
        />
      </Modal>
    );
  };

  return (
    <div className="second_fold_wrapper">
      <div className="fold_wrapper">
        <h2 className="title">
          Real-Time Image Editing at Scale, Tailored for Your Needs
        </h2>
        <div className="wrapper">
          <div className="tab_section">
            <button
              onClick={() => handleActive("FlowEdit")}
              className={active === "FlowEdit" ? "active" : ""}
            >
              FlowEdit™
            </button>
            <button
              onClick={() => handleActive("ComprehensiveEditing")}
              className={active === "ComprehensiveEditing" ? "active" : ""}
            >
              Comprehensive Editing
            </button>
          </div>
          <div className="content">
            {active === "FlowEdit"
              ? flowData?.map((flow) => {
                  return (
                    <div className="flow_wrapper" id="flow_edit">
                      <h2 className="experience">{flow.title}</h2>
                      <p className="experience_text">
                        {flow.subTitle}
                        <span className="highlight">
                          {" " + flow.subTitle2}
                        </span>
                        {" " + flow.subTitle3}
                      </p>
                      <div className="features">
                        {flow.features.map((feature) => {
                          return (
                            <div className="feature_box">
                              <img src={feature.icon} alt="" loading="lazy" />
                              <h2 className="box_title">{feature.title}</h2>
                              <p className="box_content">{feature.text}</p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="tab_section bottom_section">
                        <button
                          onClick={(e) =>{
                            e.preventDefault();
                            handleShowActive("Get Started in Minutes");
                            setShowContact(true);
                          }
                          }
                          className={
                            showActive === "Get Started in Minutes"
                              ? "active"
                              : ""
                          }
                        >
                          Get Started in Minutes
                        </button>
                        <button
                          onClick={() => {
                            handleShowActive("See How FlowEdit");
                            document
                              .getElementById("section")
                              .scrollIntoView({ behavior: "smooth" });
                              setShowActive('Get Started in Minutes');
                          }}
                          className={
                            showActive === "See How FlowEdit" ? "active" : ""
                          }
                        >
                          See How FlowEdit™ Works
                        </button>
                      </div>
                    </div>
                  );
                })
              : comprehensiveData?.map((item) => {
                  return (
                    <div className="flow_wrapper" id="comprehensive">
                      <h2 className="experience">{item.title}</h2>
                      <p className="experience_text">
                        {item.subTitle + " "}
                        <span className="comprehensive_highlight">
                          {item.subTitle2 + " "}
                        </span>
                        {item.subTitle3}
                      </p>
                      <div className="features comprehensive">
                        {item.features.map((feature) => {
                          return (
                            <div className="feature_box comprehensive_box">
                              <img src={feature.icon} alt="" loading="lazy" />
                              <h2 className="box_title">{feature.title}</h2>
                            </div>
                          );
                        })}
                      </div>
                      <div className="btn_section">
                        <button>
                          <Link to="/comprehensive-editing">Know More</Link>
                        </button>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      {showContact && getContactUsModal()}
    </div>
  );
};

export default SecondFold;
