import React, { useEffect } from "react";
import ThirdFold from "./ThirdFold";
import EighthFold from "./EighthFold";
import NinthFold from "./NinthFold";
import TenthFold from "./TenthFold";
import FourthFold from "./FourthFold";
import FifthFold from "./FifthFold";
import SixthFold from "./SixthFold";
import TwelthFold from "./TwelthFold";
import SeventhFold from "./SeventhFold";
import TwentyFold from "./TwentyFold";
import SecondFold from "./SecondFold";
import FirstFold from "./FirstFold";
import EleventhFold from "./EleventhFold";
import ThirteenFold from "./ThirteenFold";
import Calculator from "./Calculator";
import { useLocation, useParams } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const { scrollTo } = useParams(); // Extract the scrollTo param

  useEffect(() => {
    console.log("scroll", scrollTo);
    if (scrollTo === "1") {
      document
        .getElementById("flow_edit")
        ?.scrollIntoView({ behavior: "smooth" });
    }
    if (scrollTo === "2") {
      document
        .getElementById("calculator_section")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, scrollTo]);

  const getFirstFold = () => {
    return <FirstFold />;
  };
  const getSecondFold = () => {
    return <SecondFold />;
  };
  const getThirdFold = () => {
    return <ThirdFold />;
  };
  const getFourthFold = () => {
    return <FourthFold />;
  };
  const getFifthFold = () => {
    return <FifthFold />;
  };
  const getSixthFold = () => {
    return <SixthFold />;
  };
  const getSeventhFold = () => {
    return <SeventhFold />;
  };
  const getTwentyFold = () => {
    return <TwentyFold />;
  };
  const getEighthFold = () => {
    return <EighthFold />;
  };
  const getNinthFold = () => {
    return <NinthFold />;
  };

  const getCalculator = () => {
    return <Calculator />;
  };
  const getTenthFold = () => {
    return <TenthFold />;
  };
  const getEleventhFold = () => {
    return <EleventhFold />;
  };
  const getTwelthFold = () => {
    return <TwelthFold />;
  };
  const getThirteenFold = () => {
    return <ThirteenFold />;
  };
  return (
    <div className="home_component">
      {/* <BeforeAfterSlider
      beforeImage={before}
      afterImage={after}
     />
     <BeforeAfterSlider
      beforeImage={ghostImage1}
      afterImage={ghostImage2}
     /> */}
      {getFirstFold()}
      {getSecondFold()}
      {getThirdFold()}
      {getFourthFold()}
      {getFifthFold()}
      {getSixthFold()}
      {getSeventhFold()}
      {getTwentyFold()}
      {getEighthFold()}
      {/* {getNinthFold()} */}
      {getCalculator()}
      {getTenthFold()}
      {getEleventhFold()}
      {getTwelthFold()}
      {getThirteenFold()}
    </div>
  );
};

export default Home;
