import React from "react";
import before from "../../../assets/Vpi/images/ThirdFold/before.svg";
import after from "../../../assets/Vpi/images/ThirdFold/after.svg";
import replace from "../../../assets/Vpi/images/ThirdFold/replace.svg";
import replaceModels from "../../../assets/Vpi/images/ThirdFold/replace-models.svg";
import replace2 from "../../../assets/Vpi/images/ThirdFold/replace2.svg";
import vpiLighting from "../../../assets/Vpi/images/ThirdFold/vpi-re-lighting.svg";
import lightingBefore from "../../../assets/Vpi/images/ThirdFold/lighting-before.svg";
import lightingAfter from "../../../assets/Vpi/images/ThirdFold/lighting-after.svg";

// import before from "../../../assets/images/ThirdFold/before.svg";
// import after from "../../../assets/images/ThirdFold/after.svg";
// import replace from "../../../assets/images/ThirdFold/replace.svg";
// import replaceModels from "../../../assets/images/ThirdFold/replace-models.svg";
// import replace2 from "../../../assets/images/ThirdFold/replace2.svg";
// import vpiLighting from "../../../assets/images/ThirdFold/vpi-re-lighting.svg";
// import lightingBefore from "../../../assets/images/ThirdFold/lighting-before.svg";
// import lightingAfter from "../../../assets/images/ThirdFold/lighting-after.svg";
import "./style.scss";

const ThirdFold_v1 = () => {
  const data = [
    {
      id: 1,
      title: "Enhance Quality:",
      description:
        "Vendor-provided images often lack the resolution and sharpness needed. Our Latent Upscaler uses generative AI to enhance image quality, removing compression artifacts and ensuring every image is crisp and clear.",
      image: before,
      image2: after,
      isHavingImage: true,
    },
    {
      id: 2,
      title: "Replace Background",
      description:
        "Change the background to perfectly match your brand's aesthetic. Using advanced image matting, perspective matching, and generative AI, we deliver high-fidelity imagery in no time.",
      image: replace,
      image2: replace2,
      isHavingImage: true,
    },
    {
      id: 3,
      title: "Replace Models",
      description:
        "We can replace models in Vendor Provided Imagery with your shortlisted MetaModels.",
      image: replaceModels,
      image2: null,
      isHavingImage: true,
    },
    {
      id: 4,
      title: "Reframe / Recrop / Outpaint",
      description:
        "Need to adjust the framing or extend the image? Our machine learning vision algorithms and generative AI methods can tweak and expand images to fit your needs seamlessly.",
      image: vpiLighting,
      image2: null,
      isHavingImage: true,
    },
    {
      id: 5,
      title: "Re-lighting",
      description:
        "If your brand requires specific lighting, we've got you covered. By analysing depth and normal vectors, we can modify the lighting to match your style, using techniques inspired by photography and 3D rendering.",
      image: lightingBefore,
      image2: lightingAfter,
      isHavingImage: true,
    },
    {
      id: 6,
      title: "Refine Visual Qualities",
      description:
        "From sharpness and contrast to noise reduction and shadow adjustments, our deep pixel analysis and AI vision tools refine every nuance to give you a toolkit that works at scale.",
      isHavingImage: false,
    },
  ];

  return (
    <div className="third_fold_v1">
      <div className="container">
        <div className="third_wrapper">
          <h2 className="title_third">How ImageEdit Transforms Your VPI:</h2>
          {data.map((data) => {
            return (
              <>
                <h3 className="sub_title">{data.title}</h3>
                <p className="text">{data.description}</p>
                {data.isHavingImage ? (
                  <div className="before_after">
                    <div
                      className={`image_wrapper ${
                        data.image2 === null ? "image_full" : ""
                      }`}
                    >
                      {data.image2 && (
                        <>
                          <img src={data.image} alt="" />
                          <img src={data.image2} alt="" />
                        </>
                      )}
                      {data.image2 === null && <img src={data.image} alt="" />}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ThirdFold_v1;
