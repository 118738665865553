import React from "react";
import "./style.scss";

const FourthFold_v1 = () => {
  return (
    <div className="fourth_fold_v1">
      <div className="container">
        <div className="fourth_wrapper">
          <h2 className="fourth_head">Meet Metamodels.ai</h2>
          <p className="fourth_text">
            Alongside ImageEdit’s amazing tools, we present Metamodels—your
            solution for creating versatile, lifelike models at scale. Whether
            you need plus-size, petite, or any specific look, Metamodels
            delivers. Swap the person while keeping the outfit the same, making
            your fashion imagery even more dynamic and tailored to your needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FourthFold_v1;
