import React from 'react';
import Home from '../../components/Home';

const HomePage = () =>{
    return(
        <div className='home_page'>
            <Home/>
        </div>
    )
}

export default HomePage;