import React from "react";
import image from "../../../assets/Home/SeventhFold/image.gif";
import image1 from "../../../assets/Home/SeventhFold/image1.webp";
import image3 from "../../../assets/Home/SeventhFold/image3.webp";
import image4 from "../../../assets/Home/SeventhFold/image4.webp";
import image5 from "../../../assets/Home/SeventhFold/image5.webp";
import "./style.scss";

const SeventhFold = () => {
  const seventhFoldData = [
    {
      id: 1,
      title: "Customer-FlowEdit System Integration",
      text: [
        "Set up a direct integration between the customer’s camera or in-house photo studio system and FlowEdit",
        "This can be achieved via APIs or a cloud-based file-sharing system that allows images to automatically upload to FlowEdit as soon as they are captured.",
      ],
      imgUrl: image1,
    },
    {
      id: 2,
      title: "AI-Powered Real-Time Editing",
      text: [
        "FlowEdit’s AI engine starts processing each image immediately upon upload.",
        "The AI performs standard edits like background removal, color correction, lighting adjustments, or other client-specified edits",
        "This process is optimized to handle a continuous stream of images with minimal latency.",
      ],
      imgUrl: image,
    },
    {
      id: 3,
      title: "Live Monitoring & QC by Editing Experts",
      text: [
        "While the AI handles initial edits, image editing artists continuously monitor the stream of images.",
        "They perform real-time quality checks and make adjustments if the AI output doesn’t fully meet brand standards.",
        "They can flag any issues early, ensuring each image is perfect before being sent back to the customer. ",
      ],
      imgUrl: image3,
    },
    {
      id: 4,
      title: "Instant Delivery Back to the Customer",
      text: [
        "Once an image is edited and QC’d, it is immediately sent back to the customer’s system in near real time.",
        "Images can be delivered via cloud storage, a direct download link, or even an automated push to the customer’s product management system.",
        "The system is designed to ensure that the customer receives the edited images within seconds or minutes of capture, allowing them to review or use the images as soon as they are ready.",
      ],
      imgUrl: image4,
    },
    {
      id: 5,
      title: "FlixIT – Review Portal for Feedback & Continuous Refinement:",
      text: [
        "The customer can provide immediate feedback on the edited images, and FlowEdit can adjust settings dynamically for future images in the same session.",
        "The system can also learn from feedback, continuously improving the editing process based on specific customer needs.",
      ],
      imgUrl: image5,
    },
  ];

  return (
    <div className="seventh_fold_wrapper">
      <div className="editing_workflow">
        <h2 className="workFlow_heading">
          Real-Time Continuous Editing Workflow
        </h2>
        <div className="workflow_features">
          {seventhFoldData?.map((seventh) => {
            return (
              <div
                className={`workflow_box ${
                  seventh.id % 2 === 0 ? "reverse" : ""
                }`} // Add 'reverse' class for even IDs
                key={seventh.id}
              >
                <div className="workflow_description">
                  <h3 className="title_heading">{seventh.title}</h3>
                  {seventh.text &&
                    seventh.text.map((line, index) => (
                      <p className="text_description" key={index}>
                        {line}
                      </p> // Rendering each line of the text array
                    ))}
                </div>
                <div className="workflow_image">
                  <img src={seventh.imgUrl} alt="" loading="lazy" />
                  {/* <BeforeAfterSlider
                                beforeImage={ghostImage1}
                                afterImage={ghostImage2}
                            /> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeventhFold;
