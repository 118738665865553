import React from 'react';
import './style.scss';

const FourthFold = () =>{

    const fourthFoldData = [
        {
            id:1,
            step:'Step-1',
            description:'Upload your images through our easy-to-use platform.'
        },
        {
            id:2,
            step:'Step-2',
            description:'FlowEdit’s AI instantly processes your images, removing backgrounds and enhancing colors.'
        },
        {
            id:3,
            step:'Step-3',
            description:'Our human experts perform a final quality check to ensure perfection.'
        },
        {
            id:4,
            step:'Step-4',
            description:'Download your edited images in less than 10 minutes.'
        },
    ]

    return(
        <div className='fourth_fold_wrapper' id="section">
            <div className='realtime_list'>
                <h2 className='list-title'>Get real-time, high-quality edits in 4 simple steps.</h2>
                <div className='listing'>
                    {
                        fourthFoldData?.map((fourth)=>{
                            return(
                                <div className='list_box'>
                                    <p className='description'>{fourth.description}</p>
                                    <div className='stepper'>
                                        <span className="step_title">{fourth.step}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FourthFold;