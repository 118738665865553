import React, { useEffect } from 'react';
import FirstFoldInner from './FirstFoldInner';
import SecondFoldInner from './SecondFoldInner';
import ThirdFoldInner from './ThirdFoldInner';
import FourthFoldInner from './FourthFoldInner';
import FifthFoldInner from './FifthFoldInner';
import SixthFoldInner from './SixthFoldInner';
import SeventhFoldInner from './SeventhFoldInner';
import ThirteenFold from '../Home/ThirteenFold';
import { useLocation, useParams } from 'react-router-dom';

const FlowEdit = () =>{

    const location = useLocation();
  const { scrollTo } = useParams(); // Extract the scrollTo param

  useEffect(() => {
    console.log('scroll',scrollTo);
    if (scrollTo === "1") {
      document.getElementById("flow_edit")?.scrollIntoView({ behavior: "smooth" });
    }
    if(scrollTo === "2"){
      document.getElementById("calculator_section")?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, scrollTo]); // Add scrollTo as a dependency to re-run effect if it changes


    const getFirstFoldInner =() =>{
        return <FirstFoldInner/>
    }

    const getSecondFoldInner =() =>{
        return <SecondFoldInner/>
    }

    const getThirdFoldInner =() =>{
        return <ThirdFoldInner/>
    }

    const getFourthFoldInner =() =>{
        return <FourthFoldInner/>
    }

    const getFifthFoldInner =() =>{
        return <FifthFoldInner/>
    }

    const getSixthFoldInner =() =>{
        return <SixthFoldInner/>
    }

    const getSeventhFoldInner =() =>{
        return <SeventhFoldInner/>
    }

    return(
        <div className='flow_edit_wrapper'>
            {getFirstFoldInner()}
            {getSecondFoldInner()}
            {getThirdFoldInner()}
            {getFourthFoldInner()}
            {getFifthFoldInner()}
            {getSixthFoldInner()}
            {getSeventhFoldInner()}
            <ThirteenFold/>
        </div>
    )
}

export default FlowEdit;