import React from "react";
import { Routes, Route } from "react-router-dom";
import { routesList } from "./pageRoutes";

const Router = () => {
  // const {
  //   user,
  //   isAuthenticated,
  //   setOpen,
  //   isLoginPopUp,
  //   dispatcher,
  //   isLoggedInByPricing,
  // } = useAuthContext();
  // let prop = {
  //   ...user,
  //   isAuthenticated,
  //   setOpen,
  //   isLoginPopUp,
  //   dispatcher,
  //   isLoggedInByPricing,
  // };
  return (
    <Routes>
      {routesList?.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          element={
            route.provider ? (
              <route.provider>
                <route.component />
              </route.provider>
            ) : (
              <route.component />
            )
          }
        />
      ))}
    </Routes>
  );
};

export default Router;
