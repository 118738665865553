import React from "react";
import image1 from "../../../assets/Home/EleventhFold/image1.webp";
import image2 from "../../../assets/Home/EleventhFold/image2.webp";
import image3 from "../../../assets/Home/EleventhFold/image3.webp";
import image4 from "../../../assets/Home/EleventhFold/image4.webp";
import image5 from "../../../assets/Home/EleventhFold/image5.webp";
import "./style.scss";

const EleventhFold = () => {
  const EleventhData = [
    {
      id: 1,
      imgUrl: image1,
      title: "Fashion Retailer",
      text1: "FlowEdit ",
      text2: "transformed our workflow. ",
      text3: "We ",
      text4: "reduced our image editing time by 80% ",
      text5: "while ",
      text6: "maintaining the same high quality ",
      text7: "thanks to their AI-powered real-time editing.",
    },
    {
      id: 2,
      imgUrl: image2,
      title: "Photography Studio",
      text1: "The AI-powered editing tools from FlowEdit have ",
      text2: "streamlined our post-production process. ",
      text3: "We’ve ",
      text4: "cut down manual work by 70%, saving time ",
      text5: "and ",
      text6: "delivering flawless ",
      text7: "images to ",
      text8: "our clients faster.",
    },
    {
      id: 3,
      imgUrl: image3,
      title: "Marketplace Client",
      text1: "We were able to ",
      text2: "process thousands of product images in minutes— ",
      text3: "no delays, no hassle. The ",
      text4: "cost savings were significant, ",
      text5: "and ",
      text6: "the quality is unmatched.",
    },
    {
      id: 4,
      imgUrl: image4,
      title: "Online Retailer",
      text1: "FlowEdit has completely ",
      text2: "transformed how we handle high-volume image batches. ",
      text3: "We now process them ",
      text4: "faster than ever, ",
      text5: "while maintaining ",
      text6:
        "exceptional quality and consistency across all our product photos. ",
      text7: "Plus, the ",
      text8: "cost savings ",
      text9: "have been remarkable.",
    },
    {
      id: 5,
      imgUrl: image5,
      title: "Fashion Brand ",
      text1: "With FlowEdit, we’ve ",
      text2: "drastically reduced our editing time. ",
      text3: "The AI ensures our images ",
      text4: "look professional and consistent, ",
      text5: "helping us ",
      text6: "maintain brand quality without delay",
    },
    {
      id: 6,
      imgUrl: image2,
      title: "Studio Manager",
      text1: "FlowEdit ",
      text2: "transformed our studio’s workflow. ",
      text3: "Tasks that used to take hours are ",
      text4: "now done in minutes, ",
      text5: "and the ",
      text6: "quality is always consistent. ",
      text7: "It’s a must-have for any studio ",
      text8: "handling large volumes.",
    },
  ];

  return (
    <div className="eleventh_fold_wrapper">
      <div className="eleventh_wrapper">
        <h2 className="testimonial_heading">
          Hear What Our Clients Have to Say
        </h2>
        <div className="section">
          {EleventhData?.map((eleventh) => {
            return (
              <div className="section_box">
                <div className="box_head">
                  <img src={eleventh.imgUrl} alt="" loading="lazy" />
                  <span className="title">{eleventh.title}</span>
                </div>
                {eleventh.id === 1 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_one">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <span className="text_bold">{eleventh.text4}</span>
                      {eleventh.text5}
                      <mark className="highlight_two">{eleventh.text6}</mark>
                      {eleventh.text7}"
                    </p>
                  </div>
                )}
                {eleventh.id === 2 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_three">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <mark className="highlight_one">{eleventh.text4}</mark>
                      {eleventh.text5}
                      <mark className="highlight_four">{eleventh.text6}</mark>
                      {eleventh.text7}
                      <mark className="highlight_two">{eleventh.text8}</mark>"
                    </p>
                  </div>
                )}
                {eleventh.id === 3 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_three">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <mark className="highlight_two">{eleventh.text4}</mark>
                      {eleventh.text5}
                      <mark className="highlight_one">{eleventh.text6}</mark>"
                    </p>
                  </div>
                )}
                {eleventh.id === 4 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_one">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <mark className="highlight_three">{eleventh.text4}</mark>
                      {eleventh.text5}
                      <mark className="highlight_two">{eleventh.text6}</mark>
                      {eleventh.text7}
                      <mark className="highlight_four">{eleventh.text8}</mark>
                      {eleventh.text9}"
                    </p>
                  </div>
                )}
                {eleventh.id === 5 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_one">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <mark className="highlight_four">{eleventh.text4}</mark>
                      {eleventh.text5}
                      <mark className="highlight_two">{eleventh.text6}</mark>"
                    </p>
                  </div>
                )}
                {eleventh.id === 6 && (
                  <div className="description">
                    <p className="text">
                      "{eleventh.text1}
                      <mark className="highlight_two">{eleventh.text2}</mark>
                      {eleventh.text3}
                      <mark className="highlight_two">{eleventh.text4}</mark>
                      {eleventh.text5}
                      <mark className="highlight_four">{eleventh.text6}</mark>
                      {eleventh.text7}
                      <mark className="highlight_one">{eleventh.text8}</mark>"
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EleventhFold;
